import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Layout from "../components/Layout"
import SEO from "../components/SEO/seo"
import { siteData } from "../data/siteData"
import SpotlightBanner from "../components/SpotlightBanner"
import ContactForm from "../components/ContactForm"

const ContactUs = () => {
  useEffect(() => {
    const myId = window.location.hash.slice(1)
    const elem = document.getElementById(myId)
    if (elem) {
      elem.scrollIntoView()
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SpotlightBannerContactUsImageQuery {
      spotlightBannerImageImage: file(
        relativePath: { eq: "contact-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO metaData={siteData.contactUsPage} siteData={siteData}/>
        {/* <!-- Banner section --> */}

        <SpotlightBanner
          bannerImage={data.spotlightBannerImageImage.childImageSharp.fluid}
          title="お問い合わせ"
        />

        {/* <!-- Contact us page section --> */}
        <section className="contact-page-section inner-padding extra-bottom">
          <div className="container">
            <div className="row contact-page-row">
              <div className="contact-box">
                <div className="col-xs-12 col-sm-6 contact-page-cols">
                  <div className="contact-page-cols-inner">
                    <h3 className="title">
                      アメリカンイングリッシュ　
                      <br /> ハウス
                    </h3>
                    <Link
                      to="https://www.google.com/maps/dir//35.1661673,136.8202231/@35.168202,136.820051,14z?hl=ja"
                      target="_blank"
                    >
                      <p className="fs-18 semibold">
                        {siteData.site.address}{" "}
                      </p>
                    </Link>
                    <p>{siteData.site.addressDirections}</p>
                    <p className="mt-20">
                      <Link href="tel:052-442-7807">
                        <i className="icon icon-call-red" />
                        {siteData.site.contactPhone}
                      </Link>
                    </p>
                    <p className="mt-20">
                      <Link href={`mailto:${siteData.site.contactEmail}`}>
                        <i className="icon icon-envelope-red" />
                        {siteData.site.contactEmail}
                      </Link>
                    </p>
                    <ul className="social-wrapper mt-30">
                      {siteData.site.sns.instagram !== "" &&(
                        <li className="social-item">
                          <Link
                            to={siteData.site.sns.instagram}
                            target="_blank"
                            className="icon-link instagram"
                            rel="noreferrer"
                          >
                            <i className="icon icon-instagram" />
                          </Link>
                        </li>
                      )}

                      {siteData.site.sns.facebook !== "" &&(
                        <li className="social-item">
                          <Link
                            to={siteData.site.sns.facebook}
                            target="_blank"
                            className="icon-link facebook"
                            rel="noreferrer"
                          >
                            <i className="icon icon-facebook" />
                          </Link>
                        </li>
                      )}

                      {siteData.site.sns.twitter !== "" &&(
                        <li className="social-item">
                          <Link
                            to={siteData.site.sns.twitter}
                            target="_blank"
                            className="icon-link twitter"
                            rel="noreferrer"
                          >
                            <i className="icon icon-twitter" />
                          </Link>
                        </li>
                      )}

                      {siteData.site.sns.youtube !== "" &&(
                        <li className="social-item">
                          <Link
                            to={siteData.site.sns.youtube}
                            target="_blank"
                            className="icon-link youtube"
                            rel="noreferrer"
                          >
                            <i className="icon icon-youtube" />
                          </Link>
                        </li>
                      )}

                    </ul>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-sm-6 contact-page-cols"
                  id="contact-form"
                >
                  <div className="contact-page-cols-inner">
                    <h3 className="title">お問合わせ</h3>
                    <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY
                      }
                    >
                      <ContactForm />
                    </GoogleReCaptchaProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default ContactUs
