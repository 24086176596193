import React from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import InputMask from 'react-input-mask';

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [formStatus, setFormStatus] = React.useState("idle") // idle || sending || success || error
  const [errorMessage, setErrorMessage] = React.useState("")

  const [isDisabled, setDisabled] = React.useState(false)

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { experience: "英語学習年数" },
  })

  const onSubmit = async data => {
    const { message, email, fname, contact, experience } = data

    setFormStatus("sending")
    setDisabled(true)

    if (!executeRecaptcha) {
      setFormStatus("error")
      setDisabled(false)
      return
    }

    const recaptchaToken = await executeRecaptcha("contactForm")

    const formData = {
      toEmail: process.env.GATSBY_MYSERVERJAPAN_API_CONTACT_FORM_TO_EMAIL,
      fromEmail: email,
      fromName: fname,
      subject: "ウェブサイトフォーム",
      message: `
      氏名: ${fname}
      メールアドレス: ${email}
      電話番号: ${contact.replaceAll(" ", "")}
      英語学習年数: ${experience}
      ご質問など: ${message}
      `,
      recaptchaSiteKey: process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: recaptchaToken,
      formId: "contactForm",
      sendCopy: true,
      language: "ja",
      sendHtml: false,
    }

    const body = JSON.stringify(formData)

    const config = {
      baseURL: process.env.GATSBY_MYSERVERJAPAN_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_MYSERVERJAPAN_API_KEY}`,
        "Content-Type": "application/json",
      },
    }

    axios
      .post(process.env.GATSBY_MYSERVERJAPAN_API_CONTACT_FORM_URL, body, config)
      .then(res => {
        setTimeout(() => {
          setFormStatus("success")
          reset()
          setDisabled(false)
        }, 3000)
      })
      .catch(err => {
        if (err.response) {
          setErrorMessage(err.response.data.code)
        } else {
          setErrorMessage("わからない") // unknown
        }
        setFormStatus("error")
        reset()
        setDisabled(false)
      })
  }

  return (
    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <div className="form-group-content">
          <div className="has-float-label">
            <input
              type="text"
              className="input-control"
              name="fname"
              id="fname"
              placeholder="氏名"
              ref={register({ required: true })}
            />
            <label htmlFor="fname">氏名</label>
          </div>
          {errors.fname && (
            <span className="error-text">この項目は必須です</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-group-content">
          <div className="has-float-label">
            <input
              type="email"
              className="input-control"
              name="email"
              id="email"
              placeholder="メールアドレス"
              ref={register({ required: true })}
            />
            <label htmlFor="email">メールアドレス</label>
          </div>
          {errors.email && (
            <span className="error-text">この項目は必須です</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-group-content">
          <div className="has-float-label">
          <InputMask mask="099   9999   9999" >
            {(inputProps) => <input
                              {...inputProps}
                              type="text"
                              className="input-control"
                              name="contact"
                              id="contact"
                              placeholder="電話番号"
                              ref={register({ required: true,pattern: {
                                value: /\d\d\s\s\s\d\d\d\d\s\s\s\d\d\d\d/i
                              }})}
                            />}
          </InputMask>
            
            <label htmlFor="contact">電話番号</label>
          </div>
          {errors.contact && errors.contact.type === "required" && (
            <span className="error-text">この項目は必須です</span>
          )}
           {errors.contact && errors.contact.type === "pattern" && (
            <span className="error-text">有効な電話番号を入力して下さい</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-group-content">
          <select
            id="experience"
            className="input-control select"
            name="experience"
            ref={register({ required: true })}
            defaultValue="default"
          >
            <option value="英語学習年数">
              英語学習年数
            </option>
            <option value="０〜１年">０〜１年</option>
            <option value="１〜２年">１〜２年</option>
            <option value="２〜３年">２〜３年</option>
            <option value="３年以上">３年以上</option>


          </select>
          {errors.experience && (
            <span className="error-text">この項目は必須です</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <div className="form-group-content">
          <div className="has-float-label">
            <textarea
              name="message"
              className="input-control textarea"
              id="message"
              placeholder="ご質問など"
              ref={register({ required: true })}
            />
            <label htmlFor="message">ご質問など</label>
          </div>
          {errors.message && (
            <span className="error-text">この項目は必須です</span>
          )}
        </div>
      </div>
      {/* {isShowSuccessMessage && <p>#67 success</p>} */}

      {formStatus === "error" && <div>{errorMessage}</div>}

      {formStatus === "sending" && <div>データを送信しています...</div>}

      {formStatus === "success" && (
        <div>リクエストを受け取りました。まもなくご連絡いたします。</div>
      )}

      <div className="form-group">
        <div className="button-wrap">
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={isDisabled}
          >送信
          </button>
        </div>
      </div>
    </form>
  )
}
export default ContactForm
