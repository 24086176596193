import React from "react"
import Img from "gatsby-image"

const SpotlightBanner = props => {
  // const data = useStaticQuery(graphql`
  //   query SiteEnTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  const { bannerImage, title, subtitle } = props


  return (
    <>
      <section className="inner-banner-section">
        <ul className="inner-banner-wrapper">
          <li className="inner-banner-item">
            <Img fluid={bannerImage} className="inner-banner-img" imgStyle={{ objectPosition: "center" }} />
            <div className="inner-banner-title-wrapper">
              <div className="inner-banner-title">

                <h1 className="title">{title}</h1>
                {subtitle && (<h2 className="subtitle">{subtitle}</h2>)}

              </div>

            </div>
          </li>
        </ul>
      </section>
    </>
  )
}

export default SpotlightBanner